import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pathMainCatalogItems, CatalogState } from '@store/ducks/catalog';
import { CONTEXT, Context } from '@vsemayki/url-resolver';
import {
    Product,
    Relation,
    TransformRefs,
    Tag,
    Variant,
    Query,
    Category,
} from '@src/types/product';
import { Color, Size } from '@src/types/references';
import {
    ApiCatalogItemsResponse,
    ApiCatalogTagsResponse,
} from '@api/types/catalog';
import { AppThunk, RootState } from '@store/ducks/index';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import isMatch from 'lodash/isMatch';
import { buildBreadcrumbs } from './application/breadcrumbs';
import { loadingActions } from './application/loading';
import { isEqual } from 'lodash';
import { contextSelector } from './application/context';
import { shopSettingsSelector } from '@ducks/application/shopSettings';
import keyBy from 'lodash/keyBy';
import Router from 'next/router';

const { apiRequest } = ApiMiddleware;

type ProductWithInfo = Omit<Product, 'variants'> & {
    variants: Array<Variant & { info: { color: Color; size: Size } }>;
};

export type RelatedItems = {
    id: string;
    title: string;
    position: number;
    title_genitive: string;
    title_plural_genitive: string;
    title_plural_accusative: string;
    items: CatalogState['items'];
};

export interface ProductState {
    data?: ProductWithInfo;
    recommendedItems: CatalogState['items'];
    activeVariant?: Variant;
    sizeInfo: string;
    error: boolean;
    activeSide: number;
    relatedItems: Array<RelatedItems>;
    initialTab: string;
}

const initialState: ProductState = {
    data: undefined,
    recommendedItems: [],
    activeVariant: undefined,
    sizeInfo: '',
    error: false,
    activeSide: 0,
    relatedItems: [],
    initialTab: '',
};

const product = createSlice({
    name: 'Product',
    initialState,
    reducers: {
        startFetchProduct: (state: ProductState) => {
            state.error = false;
        },
        setProduct: (
            state: ProductState,
            action: PayloadAction<ProductWithInfo>
        ) => {
            state.activeSide = initialState.activeSide;
            state.data = action.payload;
        },
        setActiveVariant: (
            state: ProductState,
            action: PayloadAction<Variant>
        ) => {
            state.activeVariant = action.payload;
        },

        setRecommendedItems: (
            state: ProductState,
            action: PayloadAction<CatalogState['items']>
        ) => {
            state.recommendedItems = action.payload;
        },
        setSizeInfo: (state: ProductState, action: PayloadAction<string>) => {
            state.sizeInfo = action.payload;
        },
        notFound: (state: ProductState) => {
            state.error = true;
        },
        setActiveSide: (state: ProductState, action: PayloadAction<number>) => {
            state.activeSide = action.payload;
        },
        getRelatedProducts: (
            state: ProductState,
            action: PayloadAction<CatalogState['items']>
        ) => {
            state.recommendedItems = action.payload;
        },
        setActiveRelatedTab: (
            state: ProductState,
            action: PayloadAction<string>
        ) => {
            state.initialTab = action.payload;
        },
        setRelatedItems: (
            state: ProductState,
            action: PayloadAction<Array<RelatedItems>>
        ) => {
            state.relatedItems = action.payload;
        },
    },
});
export const productActions = product.actions;
export default product.reducer;

const getRecommendedProducts: (category: Category, tag: Tag) => AppThunk = (
    category,
    tag
) => async (dispatch, getState) => {
    const ShopState = shopSettingsSelector(getState());
    try {
        const recommendedItems = await dispatch<ApiCatalogItemsResponse>(
            apiRequest({
                method: 'GET',
                url: '/rest/catalog/items',
                params: {
                    'filter[category]': category.id,
                    'filter[tag]': tag.id,
                    limit: 4,
                    offset: 0,
                    'filter[force_partner]': [1099463, 93073].includes(
                        ShopState.id || 0
                    )
                        ? 1
                        : undefined,
                },
            })
        );
        const { references } = getState().application;
        const mapedItems = pathMainCatalogItems(
            references || { color: [], sex: [], size: [] },
            false
        )(recommendedItems.items);
        dispatch(productActions.setRecommendedItems(mapedItems || []));
    } catch (e) {
        console.warn(e);
    }
};

const getSizeInfo: (productType: string) => AppThunk = (productType) => async (
    dispatch
) => {
    try {
        const { text } = await dispatch(
            apiRequest({
                url: `/rest/catalog/product/size/${productType}`,
            })
        );
        dispatch(productActions.setSizeInfo(text));
    } catch (e) {
        console.warn(e);
    }
};

const mapRelations = (relations: Relation[], refs: TransformRefs) => {
    return relations.reduce<ProductWithInfo['variants'][0]['info']>(
        (rel, current) => ({
            ...rel,
            [current.name]: refs[current.name][current.id],
        }),
        {} as ProductWithInfo['variants'][0]['info']
    );
};

const patchProduct = (
    refs: TransformRefs,
    product: Product,
    tags: ApiCatalogTagsResponse
) => {
    const category = product.categories[0];
    const groupedTags = keyBy(tags, 'id');
    const [tag] = product.tags.filter(
        (tag) => typeof groupedTags[tag.id] !== 'undefined'
    );

    return {
        ...product,
        variants:
            product.variants &&
            product.variants
                .filter((varian) => varian.available)
                .map((v) => {
                    return {
                        ...v,
                        info: mapRelations(v.relations, refs),
                    };
                }),
        category,
        tag,
    };
};

export const findSuitableVariant: (query: Query) => AppThunk = (
    query
) => async (dispatch, getState) => {
    const State = getState();
    const product = getState().product.data;
    const ContextState = contextSelector(State);

    if (!product) {
        return;
    }

    const suitableVariant = product?.variants.find(
        (variant) =>
            variant.info &&
            isMatch(
                {
                    color: variant.info.color.alias,
                    size: variant.info.size.alias,
                },
                query
            )
    );
    const activeVariant = suitableVariant || product?.variants[0];
    dispatch(productActions.setActiveVariant(activeVariant));
    const contextData = getState().application.context;
    const context = new Context();
    context.fill(contextData);
    context.setName('article');
    context.setQuery({
        size: query.size,
        color: query.color,
    });

    if (
        process.browser &&
        typeof document !== 'undefined' &&
        query &&
        !isEqual(query, ContextState.query)
    ) {
        Router.replace(CONTEXT.article, context.getUrl(), { shallow: true });
    }
};

export const productSelector = (state: RootState) => state.product;

export const fetchproduct: (
    productType: string,
    design: number,
    query: Query
) => AppThunk = (productType, design, query) => async (dispatch, getState) => {
    dispatch(productActions.startFetchProduct());
    try {
        dispatch(loadingActions.setLoading(true));
        const product = await dispatch<Product>(
            apiRequest({
                url: `/rest/catalog/item/${design}_${productType}`,
            })
        );

        const references = getState().application.references;
        const tags = getState().application.tags;
        if (!references) {
            throw new Error('No references');
        }

        const mapped = {
            color: keyBy(references.color, 'id'),
            size: keyBy(references.size, 'id'),
        };
        const transformedProduct = patchProduct(mapped, product, tags);

        await dispatch(getSizeInfo(transformedProduct.category.id));
        if (transformedProduct.tag) {
            await dispatch(
                getRecommendedProducts(
                    transformedProduct.category,
                    transformedProduct.tag
                )
            );
            dispatch(
                buildBreadcrumbs({
                    tag: transformedProduct.tag.id,
                    product: transformedProduct.category.id,
                })
            );
        }
        dispatch(productActions.setProduct(transformedProduct));
        await dispatch(findSuitableVariant(query));

        dispatch(loadingActions.setLoading(false));
    } catch (e) {
        dispatch(productActions.notFound());
        dispatch(loadingActions.setLoading(false));
    }
};

export const getRelatedProducts: (
    design: number,
    productType: string
) => AppThunk = (design, productType) => async (dispatch) => {
    try {
        const relatedItems = await dispatch<Array<RelatedItems>>(
            apiRequest({
                url: `/catalogue/related/${design}`,
            })
        );

        const activeRelatedTab = relatedItems.find((group: RelatedItems) => {
            return group.items.some(
                (item: { category: { id: string } }) =>
                    item.category.id === productType
            );
        });

        dispatch(productActions.setRelatedItems(relatedItems));
        dispatch(
            productActions.setActiveRelatedTab(
                (activeRelatedTab && activeRelatedTab.id) || ''
            )
        );
    } catch (e) {
        console.error('Related failed:', e);
    }
};
